/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import { useCallback } from "react";

import "react-datepicker/dist/react-datepicker.css";
import { formatToLocaleCurrency } from "../../../../utils/string";
import { getPaymentName } from "../../../../modules/finance/transaction-payment/enum/Method";
import { TransactionService } from "../../../../modules/finance/transaction/TransactionService";
import { useToast } from "../../../../hooks/Toast";

const transactionProvider = new TransactionService();

const CloseOrderPopup = ({ item, setLoading, onUpdate }) => {
	const { showSuccess, showError } = useToast();

	const handleCloseOrder = () => {
		setLoading(true);
		transactionProvider
			.closeTransaction(item.id)
			.then(() => {
				showSuccess("Pedido fechado com sucesso.");
				setLoading(false);
				if (onUpdate) onUpdate(item);
			})
			.catch((error) => {
				showError(error);
				setLoading(false);
			});
	};

	const getCashback = useCallback(() => {
		if (!item) return 0;
		const { totalRaw, totalCashback = 0 } = item;
		let preCashback = (totalRaw ? (totalCashback / totalRaw) * 100 : 0).toFixed(
			0
		);
		preCashback =
			preCashback === 0
				? preCashback
				: Math.round(item.items[0].product.discount * 100);
		return preCashback;
	}, [item]);

	return (
		<div
			id="closeOrderModal"
			className="modal fade"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="success-header-modalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header modal-colored-header bg-success">
						<h4 className="modal-title" id="success-header-modalLabel">
							Fechar Pedido
						</h4>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-hidden="true"
						/>
					</div>
					<div className="modal-body">
						Deseja fechar o pedido?
						<br />
						<h5>{item?.items[0].product?.title}</h5>
						<strong>Forma de Pagamento:</strong>{" "}
						{!item?.payments
							? ""
							: item.payments
									.map((p) => getPaymentName(p.method || ""))
									.join("; ")
									.trim()}
						<br />
						<strong>Valor:</strong> {formatToLocaleCurrency(Number(item?.total || 0))}
						<br />
						<strong>Cashback:</strong> {getCashback()}%
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-light" data-bs-dismiss="modal">
							Não
						</button>
						<button
							type="button"
							className="btn btn-success"
							data-bs-dismiss="modal"
							onClick={handleCloseOrder}
						>
							Sim
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CloseOrderPopup;
