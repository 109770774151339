import { useEffect, useState } from "react";
import { Spinner, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import VividusCardPage from "../../components/VividusCardPage";
import { useAuth } from "../../contexts/AuthContext";
import { useToast } from "../../hooks/Toast";
import { emailRegex } from "../../utils/string";
import FooterBar from "../DashboardPage/components/FooterBar/FooterBar";

const invalidBorder = "1px solid #f44336";
const validBorder = "1px solid #4caf50";

const LoginPage = () => {
	const { currentUser, login, loaded } = useAuth();
	const { showError } = useToast();
	const navigate = useNavigate();

	const [emailBorder, setEmailBorder] = useState("1px solid #dee2e6");
	const [passwordBorder, setPasswordBorder] = useState("1px solid #dee2e6");

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const [isRememberMe, setIsRememberMe] = useState(true);
	const [isShowPassword, setIsShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleChangeEmail = (event) => {
		const { value } = event.target;
		if (!value || !emailRegex.test(value)) {
			setEmailBorder(invalidBorder);
		} else {
			setEmailBorder(validBorder);
		}
		setEmail(value);
	};

	const handleChangePassword = (event) => {
		const { value } = event.target;
		if (!value) {
			setPasswordBorder(invalidBorder);
		} else {
			setPasswordBorder(validBorder);
		}
		setPassword(value);
	};

	const signIn = async (e) => {
		e.preventDefault();
		if (!email) {
			showError("E-mail não informado.");
			setEmailBorder(invalidBorder);
			return;
		}
		if (!emailRegex.test(email)) {
			showError("E-mail inválido.");
			return;
		}
		if (!password) {
			showError("Senha não informada.");
			setPasswordBorder(invalidBorder);
			return;
		}
		setLoading(true);
		try {
			if (!(await login(email, password, isRememberMe))) {
				showError("Usuário não encontrado.");
				setLoading(false);
			}
		} catch (e) {
			let errorMessage = "Falha ao entrar: ";
			switch (e.code) {
				case "auth/user-not-found":
					errorMessage += "Usuário não encontrado.";
					break;
				case "auth/invalid-email":
				case "auth/wrong-password":
					errorMessage += "Senha ou e-mail inválido.";
					break;
				default:
					errorMessage += `Falha ao entrar: ${e.message}`;
					break;
			}
			setLoading(false);
			showError(errorMessage);
		}
	};

	useEffect(() => {
		if (currentUser) {
			navigate("/order");
		}
	}, [currentUser, navigate]);

	return (
		<div style={{ width: "100%", height: "100%", backgroundColor: "#D3D0DC" }}>
			<ToastContainer />
			{loading || !loaded ? (
				<Container
					className="d-flex align-items-center justify-content-center"
					style={{ minHeight: "100vh" }}
				>
					<Spinner animation="border" role="status" style={{ color: "#e21251" }}>
						<span className="visually-hidden">Loading...</span>
					</Spinner>
				</Container>
			) : (
				<VividusCardPage>
					<form>
						<div className="card-body p-4">
							<div className="text-center w-75 m-auto">
								<h4 className="text-dark-50 text-center mt-0 fw-bold">Parceiro</h4>
								<p className="text-muted mb-4">
									Informe abaixo seu email e senha para acessar o sistema de gestão do
									Parceiro Vívidus.
								</p>
							</div>

							<div className="position-relative  mb-3">
								<label htmlFor="emailaddress" className="form-label">
									Email
								</label>
								<input
									className="form-control"
									type="email"
									id="emailaddress"
									required
									placeholder="Informe seu email"
									autoComplete="on"
									value={email}
									onChange={handleChangeEmail}
									style={{
										border: emailBorder,
									}}
								/>
							</div>

							<div className="position-relative  mb-3">
								<span
									onClick={() => navigate("/resetpassword")}
									className="text-muted float-end"
									style={{ cursor: "pointer" }}
									role="button"
									tabIndex={-1}
								>
									<small>Esqueceu sua senha?</small>
								</span>
								<label htmlFor="password" className="form-label">
									Senha
								</label>
								<div className="input-group input-group-merge">
									<input
										type={isShowPassword ? "text" : "password"}
										id="password"
										className="form-control"
										placeholder="Digite sua senha"
										autoComplete="on"
										onChange={handleChangePassword}
										value={password}
										style={{
											border: passwordBorder,
										}}
									/>
									<div
										className={
											isShowPassword
												? "input-group-text show-password"
												: "input-group-text"
										}
										data-password="false"
										onClick={() => setIsShowPassword(!isShowPassword)}
										role="button"
										tabIndex={0}
									>
										<span className="password-eye" />
									</div>
								</div>
							</div>

							<div className="mb-3 mb-3">
								<div className="form-check">
									<input
										type="checkbox"
										className="form-check-input"
										id="checkbox-signin"
										tabIndex={-1}
										onChange={(e) => setIsRememberMe(e.target.value)}
										defaultChecked={isRememberMe}
									/>
									<label className="form-check-label" htmlFor="checkbox-signin">
										Manter conectado
									</label>
								</div>
							</div>

							<div className="mb-3 mb-0 text-center">
								<button type="button" className="btn btn-primary" onClick={signIn}>
									{" "}
									Entrar{" "}
								</button>
							</div>
						</div>
					</form>
				</VividusCardPage>
			)}
			<FooterBar type="summary" />
		</div>
	);
};

export default LoginPage;
