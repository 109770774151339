import React from "react";
import ExportCSVButton from "../../../../components/ExportCSVButton";

const DashTitle = ({
	title,
	dataCSV,
	handleExportCSV,
	handleExportPDF,
	showExportCSVButton,
	showExportPDFButton,
	showGoBackButton,
	children,
}) => (
	<div className="row">
		<div className="col-12">
			<div className="page-title-box">
				{showExportCSVButton && (
					<div className="page-title-right">
						<div className="d-flex">
							<ExportCSVButton handleExport={handleExportCSV} data={dataCSV} />
						</div>
					</div>
				)}
				{/* TODO: Botão para exportar CSV e PDF. Propriedades para exibir e as funções */}
				{children}
				<h4 className="page-title">{title}</h4>
			</div>
		</div>
	</div>
);

DashTitle.defaultProps = {
	showGoBackButton: false,
};

export default DashTitle;
