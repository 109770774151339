/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from "react";
import logo from "../../../../assets/images/adaptive-icon.png";
import SideBarItem from "./SideBarItem";

const SideBar = () => (
	<div className="leftside-menu menuitem-active">
		<div className="logo text-center">
			<span className="logo-lg">
				<img src={logo} alt="" height="40" />
			</span>
			<span className="logo-sm">
				<img src={logo} alt="" height="40" />
			</span>
		</div>
		<div
			className="h-100 show"
			id="leftside-menu-container"
			data-simplebar="init"
		>
			<div className="simplebar-wrapper" style={{ margin: "0px" }}>
				<div className="simplebar-height-auto-observer-wrapper">
					<div className="simplebar-height-auto-observer" />
				</div>
				<div className="simplebar-mask">
					<div className="simplebar-offset" style={{ right: "0px", bottom: "0px" }}>
						<div
							className="simplebar-content-wrapper"
							tabIndex={0}
							role="region"
							aria-label="scrollable content"
							style={{ height: "100%", overflow: "hidden scroll" }}
						>
							<div className="simplebar-content" style={{ padding: "0px" }}>
								<ul className="side-nav">
									<SideBarItem icon="mdi mdi-shopping" title="Pedidos" url="/order" />
								</ul>
								<div className="clearfix" />
							</div>
						</div>
					</div>
				</div>
				<div
					className="simplebar-placeholder"
					style={{ width: "auto", height: "1928px" }}
				/>
			</div>
			<div
				className="simplebar-track simplebar-horizontal"
				style={{ visibility: "hidden" }}
			>
				<div
					className="simplebar-scrollbar"
					style={{ width: "0px", display: "none" }}
				/>
			</div>
			<div
				className="simplebar-track simplebar-vertical"
				style={{ visibility: "visible" }}
			>
				<div
					className="simplebar-scrollbar"
					style={{
						height: "271px",
						transform: "translate3d(0px, 452px, 0px)",
						display: "block",
					}}
				/>
			</div>
		</div>
	</div>
);

export default SideBar;
