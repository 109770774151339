/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import "react-datepicker/dist/react-datepicker.css";
import { useToast } from "../../../../hooks/Toast";
import { TransactionService } from "../../../../modules/finance/transaction/TransactionService";

const transactionProvider = new TransactionService();

const CancelOrderPopup = ({ item, setLoading, onUpdate }) => {
	const { showSuccess, showError } = useToast();

	const handleCancelOrder = () => {
		setLoading(true);
		transactionProvider
			.cancelTransaction(item.id)
			.then(() => {
				showSuccess("Pedido cancelado com sucesso.");
				setLoading(false);
				if (onUpdate) onUpdate(item);
			})
			.catch((error) => {
				showError(error);
				setLoading(false);
			});
	};

	return (
		<div
			id="cancelOrderModal"
			className="modal fade"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="danger-header-modalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header modal-colored-header bg-danger">
						<h4 className="modal-title" id="danger-header-modalLabel">
							Cancelar Pedido
						</h4>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-hidden="true"
						/>
					</div>
					<div className="modal-body">
						Tem certeza que deseja cancelar esse pedido?
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-light" data-bs-dismiss="modal">
							Não
						</button>
						<button
							type="button"
							className="btn btn-danger"
							data-bs-dismiss="modal"
							onClick={handleCancelOrder}
						>
							Sim
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CancelOrderPopup;
