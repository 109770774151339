import axios from "axios";
import { Buffer } from "buffer";
import { VividusError } from "../errors";

export class BaseAPI {
	constructor(name, baseURL) {
		this.name = name;
		this.baseURL = baseURL;
		this.api = axios.create({
			baseURL,
		});

		this.createDebugAxios();
		this.api.interceptors.response.use(
			(response) => response,
			(error) => this.handlerAxiosError(error)
		);

		this.api.defaults.headers.post["Content-Type"] =
			"application/x-www-form-urlencoded";
		this.api.defaults.headers.post.Accept = "application/json";
	}

	static init(auth) {
		BaseAPI.jwt = `Bearer ${auth.vividusJwt}`;
	}

	static clear() {
		BaseAPI.jwt = "";
	}

	handlerAxiosErrorImp(error) {
		return error;
	}

	handlerAxiosError(error) {
		if (!error.isAxiosError || !error.response) throw error;
		this.handlerAxiosErrorImp(error);
		throw new VividusError(
			error.response.status,
			error.response.data.code,
			error.response.data.message || error.message,
			error.response.data.data
		);
	}

	createDebugAxios() {
		if (process.env.NODE_ENV === "production") return;

		const getURL = (config) => {
			let { url } = config;
			if (config.baseURL && !require("axios/lib/helpers/isAbsoluteURL")(url)) {
				url = require("axios/lib/helpers/combineURLs")(config.baseURL, url);
			}
			return require("axios/lib/helpers/buildURL")(
				url,
				config.params,
				config.paramsSerializer
			);
		};

		const options = {
			request: (config) => {
				const url = getURL(config);
				Object.defineProperty(config, "__AXIOS-DEBUG-LOG_URL__", { value: url });
				console.log(
					`(${config.method?.toUpperCase()}) ${url}`,
					this.normalizeLogRequestData(config.url || "", config.data || "")
				);
			},
			response: (response) => {
				const url = response.config["__AXIOS-DEBUG-LOG_URL__"];
				console.log(
					response.statusText,
					response.status,
					`(${response.config.method?.toUpperCase()})`,
					url,
					this.normalizeLogResponseData(
						response.config.url || "",
						response.data || ""
					)
				);
			},
			error: (error) => {
				if (error.config) {
					const url = error.config["__AXIOS-DEBUG-LOG_URL__"];
					console.error(
						error.name,
						error.message,
						`(${error.config.method?.toUpperCase()})`,
						url,
						error.data || ""
					);
				} else {
					console.error(error.name, error.message, error.data || "");
				}
			},
		};

		this.api.interceptors.request.use((config) => {
			options.request(config);
			return config;
		});
		this.api.interceptors.response.use(
			(response) => {
				options.response(response);
				return response;
			},
			(error) => {
				options.error(error);
				throw error;
			}
		);
	}

	normalizeLogRequestData(url, data) {
		return data;
	}

	normalizeLogResponseData(url, data) {
		return data;
	}

	static getBasicAuth() {
		return `Basic ${Buffer.from(
			`${process.env.REACT_APP_VIVIDUS_BACKEND_BASIC_USER}:${process.env.REACT_APP_VIVIDUS_BACKEND_BASIC_PASS}`
		).toString("base64")}`;
	}

	static getJWTAuth() {
		return BaseAPI.jwt;
	}

	getAPI() {
		return this.api;
	}
}
