import React from "react";

const TopBarProfile = ({ name, avatar }) => (
	<span
		className="nav-link dropdown-toggle nav-user arrow-none me-0"
		data-bs-toggle="dropdown"
		id="topbar-userdrop"
		role="button"
		aria-haspopup="true"
		aria-expanded="false"
	>
		<span className="account-user-avatar">
			<img
				src={avatar}
				alt="user"
				className="rounded-circle"
				referrerPolicy="no-referrer"
			/>
		</span>
		<span>
			<span className="account-user-name">{name}</span>
		</span>
	</span>
);

TopBarProfile.defaultProps = {
	name: "",
	avatar: "",
};

export default TopBarProfile;
