import Dinero from "dinero.js";

export const emailRegex =
	/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const formatToLocaleCurrency = (value) => {
	if (typeof value === "undefined") return "";
	return Dinero({ clientId: "0", amount: value }).toFormat();
};

export const parseDineroToUnitString = (value) => {
	if (typeof value === "undefined") return "";
	return String(Dinero({ amount: value }).toUnit()).replace(".", ",");
};

export const limitStrings = (string, max_length, dontAppendDots) => {
	if (!string) {
		return "";
	}
	if (string.length > max_length && !dontAppendDots) {
		return `${string.substring(0, max_length)}...`;
	}
	if (string.length > max_length) {
		return `${string.substring(0, max_length)}`;
	}
	return string;
};

export const formatCPF = (cpf) => {
	if (!cpf) return "";
	cpf = cpf.replace(/\D+/g, "");
	if (cpf.length !== 11) return cpf;
	return cpf.replace(
		/(\d{3})(\d{3})(\d{3})(\d{2})/,
		(regex, arg1, arg2, arg3, arg4) => `${arg1}.${arg2}.${arg3}-${arg4}`
	);
};

export const formatPhone = (phone) => {
	if (!phone) return "";
	phone = phone.replace(/\D+/g, "");
	if (phone.length < 8) return phone;
	if (phone.length > 11) {
		phone = phone.slice(2, phone.length);
	}
	if (phone.length > 11 || phone.length < 8) return phone;

	if (phone.length === 11) {
		return phone.replace(
			/(\d{2})(\d{5})(\d{4})/,
			(regex, arg1, arg2, arg3) => `(${arg1}) ${arg2}-${arg3}`
		);
	}
	if (phone.length === 10) {
		return phone.replace(
			/(\d{2})(\d{4})(\d{4})/,
			(regex, arg1, arg2, arg3) => `(${arg1}) ${arg2}-${arg3}`
		);
	}
	if (phone.length === 9) {
		return phone.replace(
			/(\d{5})(\d{4})/,
			(regex, arg1, arg2) => `${arg1}-${arg2}`
		);
	}
	if (phone.length === 8) {
		return phone.replace(
			/(\d{4})(\d{4})/,
			(regex, arg1, arg2) => `${arg1}-${arg2}`
		);
	}
	return phone;
};

export const maskDataPicker = (date) => {
	if (!date) return "";
	date = date.replace(/\D/g, "");
	if (date.length <= 2) return date;
	if (date.length > 12) {
		date = date.slice(0, date.length - 1);
	}
	if (date.length <= 4) {
		return date.replace(
			/(\d{1,2})(\d{2})/,
			(regex, arg1, arg2) => `${arg1}/${arg2}`
		);
	}
	if (date.length <= 8) {
		return date.replace(
			/(\d{1,2})(\d{2})(\d{2})/,
			(regex, arg1, arg2, arg3) => `${arg1}/${arg2}/${arg3}`
		);
	}
	if (date.length <= 10) {
		return date.replace(
			/(\d{1,2})(\d{2})(\d{4})(\d{2})/,
			(regex, arg1, arg2, arg3, arg4) => `${arg1}/${arg2}/${arg3} ${arg4}`
		);
	}
	if (date.length <= 12) {
		return date.replace(
			/(\d{1,2})(\d{2})(\d{4})(\d{2})(\d{2})/,
			(regex, arg1, arg2, arg3, arg4, arg5) =>
				`${arg1}/${arg2}/${arg3} ${arg4}:${arg5}`
		);
	}
	return date;
};
