import { BaseAPI } from "./BaseAPI";

export class VividusAPI extends BaseAPI {
	constructor() {
		super("AXIOS_VIVIDUS", process.env.REACT_APP_VIVIDUS_BACKEND || "");
		this.api.defaults.headers.common["x-vividus-origin"] = "PROVIDER";
	}

	async postLoginToken(auth_id) {
		return this.api
			.post(
				"api/auth/login/token",
				{
					auth_id,
				},
				{
					headers: {
						authorization: VividusAPI.getBasicAuth(),
					},
				}
			)
			.then((response) => response.data);
	}

	getUser(id, params, accesstoken) {
		return this.api
			.get(`api/security/user/${id}`, {
				params,
				headers: {
					authorization: accesstoken
						? `Bearer ${accesstoken}`
						: VividusAPI.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	getVerifyMember(params) {
		return this.api
			.get("api/auth/verify/member", {
				params,
				headers: {
					authorization: VividusAPI.getBasicAuth(),
				},
			})
			.then((response) => response.data);
	}

	async getTransactionPaginated(params) {
		const transactions = this.api
			.get("api/finance/transaction", {
				params,
				headers: {
					authorization: VividusAPI.getJWTAuth(),
				},
			})
			.then((response) => response.data);
		return transactions;
	}

	async deleteCancelTransaction(transactionID) {
		return this.api.delete(`api/finance/transaction/${transactionID}`, {
			headers: {
				authorization: VividusAPI.getJWTAuth(),
			},
		});
	}

	async postCloseTransaction(transactionID) {
		return this.api.post(
			`api/finance/transaction/${transactionID}/close`,
			undefined,
			{
				headers: {
					authorization: VividusAPI.getJWTAuth(),
				},
			}
		);
	}

	async patchTransaction(transactionID, price, cashback) {
		const result = await this.api
			.patch(
				`api/finance/transaction/${transactionID}`,
				{ price, cashback },
				{
					headers: {
						authorization: VividusAPI.getJWTAuth(),
					},
				}
			)
			.then((response) => response.data);
		return result;
	}

	async postScheduleTransaction(transactionID, date) {
		const result = await this.api
			.post(
				`api/finance/transaction/${transactionID}/schedule`,
				{ date },
				{
					headers: {
						authorization: VividusAPI.getJWTAuth(),
					},
				}
			)
			.then((response) => response.data);
		return result;
	}

	getParameterValue(code) {
		return this.api
			.get(`api/system/parameters/value/${code}`, {
				headers: {
					authorization: VividusAPI.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}
}
