import React from "react";
import { getVersion } from "../../../../config";

const getFooterTitle = () =>
	`${new Date().getFullYear()} © Vívidus | ${getVersion()}`;

const FooterBar = ({ type = "dashboard" }) => (
	<footer className={`footer ${type === "summary" ? "footer-alt" : ""}`}>
		{type === "summary" ? (
			getFooterTitle()
		) : (
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-6">{getFooterTitle()}</div>
					<div className="col-md-6">
						<div className="text-md-end footer-links d-none d-md-block">
							<a href="https://www.vividus.com.br/" rel="noreferrer" target="_blank">
								Sobre
							</a>
							<a
								href={`https://wa.me/${process.env.REACT_APP_WPP_NUMBER || ""}`}
								rel="noreferrer"
								target="_blank"
							>
								Suporte
							</a>
						</div>
					</div>
				</div>
			</div>
		)}
	</footer>
);

FooterBar.defaultProps = {
	type: "dashboard",
};

export default FooterBar;
