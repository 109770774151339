import { Outlet } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import FooterBar from "./components/FooterBar/FooterBar";
import SideBar from "./components/SideBar";
import TopBar from "./components/TopBar";

const DashboardPage = () => (
	<div className="wrapper">
		<SideBar />
		<div className="content-page">
			<div className="content">
				<TopBar />
				<Outlet />
			</div>
			<FooterBar />
		</div>
	</div>
);

export default DashboardPage;
