/* eslint-disable react/prop-types */
import React, {
	useContext,
	useState,
	useEffect,
	useCallback,
	createContext,
	useMemo,
} from "react";
import firebase from "firebase";
import { auth as authFirebase } from "../utils/firebase";
import {
	ProviderIdNotFoundError,
	UnauthorizedError,
	UserNotFoundError,
} from "../errors";
import { AuthService } from "../modules/security/auth/AuthService";
import { UserService } from "../modules/security/user/UserService";
import { initAPIS } from "../apis";

const authService = new AuthService();
const userService = new UserService();

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
	const [auth, setAuth] = useState();
	const [signed, setSigned] = useState(false);
	const [currentUser, setCurrentUser] = useState();
	/**
	 * Utilizado apenas para indicar quando o contexto foi carregado.
	 */
	const [loaded, setLoaded] = useState(false);

	const setAuthUser = useCallback(
		async (user) => {
			try {
				if (user?.uid) {
					if (currentUser && user.uid === currentUser.uid) return auth;
					const { exists } = await authService.getVerifyByEmail(user.email);
					if (!exists) {
						logout();
						return undefined;
					}
					const { id, isMaster, accesstoken, permissions } =
						await authService.loginToken(user.uid);
					if (!isMaster && !permissions.includes("provider-marketplace-acccess")) {
						logout();
						throw new UnauthorizedError();
					}
					const userLocal = await userService.fetchById(id, accesstoken);
					if (!userLocal.provider?.id) {
						logout();
						throw new ProviderIdNotFoundError();
					}
					const authLocal = {
						vividusJwt: accesstoken,
						isMaster,
						user: {
							id,
							name: userLocal.name,
							cep: userLocal.zipcode,
							avatar: userLocal.avatarUrl,
							provider_id: userLocal.provider?.id,
						},
						permissions,
					};
					setCurrentUser(user);
					setAuth(authLocal);
					initAPIS(authLocal);
					setLoaded(true);
					return authLocal;
				}
			} catch (error) {
				console.error(error);
			}
			setCurrentUser(undefined);
			setAuth(undefined);
			initAPIS(undefined);
			setLoaded(true);
			return undefined;
		},
		[auth, currentUser]
	);

	const login = useCallback(
		async (email, password, rememberMe) => {
			await authFirebase.setPersistence(
				rememberMe
					? firebase.auth.Auth.Persistence.LOCAL
					: firebase.auth.Auth.Persistence.SESSION
			);
			const crendetials = await authFirebase.signInWithEmailAndPassword(
				email,
				password
			);
			if (crendetials?.user?.email) {
				// Verifica se o usuário existe no firestore
				const { exists } = await authService.getVerifyByEmail(
					crendetials.user.email
				);
				if (!exists) throw new UserNotFoundError();
				const authLocal = await setAuthUser(crendetials.user);
				return authLocal;
			}
			throw new UserNotFoundError();
		},
		[setAuthUser]
	);

	const resetPassword = async (email) => {
		firebase.auth().languageCode = "pt_br";
		return authFirebase.sendPasswordResetEmail(email);
	};

	const logout = () => authFirebase.signOut();

	/**
	 * Valida se o usuário possui a permissão sem validar se o usuário for master.
	 */
	const containsPermission = useCallback(
		(permissions) => {
			if (!auth?.permissions) return false;
			if (!Array.isArray(permissions)) permissions = [permissions];
			return permissions.every((permission) =>
				typeof permission === "string"
					? auth.permissions.includes(permission)
					: auth.permissions.some((p) => permission.test(p))
			);
		},
		[auth?.permissions]
	);

	/**
	 * Valida se o usuário possui a permissão. Sempre retorna verdadeira se o usuário for master.
	 */
	const hasPermission = useCallback(
		(permissions) => {
			if (auth?.isMaster) return true;
			return containsPermission(permissions);
		},
		[containsPermission, auth?.isMaster]
	);

	const isMaster = useCallback(() => !!auth?.isMaster, [auth?.isMaster]);

	useEffect(() => {
		const unsubscribe = authFirebase.onAuthStateChanged((user) =>
			setAuthUser(user || undefined).catch(() => {})
		);

		return () => unsubscribe();
	}, [setAuthUser]);

	useEffect(() => {
		setSigned(!!auth && !!currentUser);
	}, [auth, currentUser]);

	const value = useMemo(
		() => ({
			signed,
			loaded,
			auth,
			currentUser,
			login,
			resetPassword,
			logout,
			hasPermission,
			isMaster,
			containsPermission,
		}),
		[
			auth,
			containsPermission,
			currentUser,
			hasPermission,
			isMaster,
			loaded,
			login,
			signed,
		]
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
	return useContext(AuthContext);
}
