export const Method = {
	CREDIT: "credit",
	DEBIT: "debit",
	CASH: "cash",
	PIX: "pix",
	CASHBACK: "cashback",
	BANK_SLIP: "bank_slip",
};



export function getPaymentName(type) {
	switch (type) {
		case Method.PIX:
			return "PIX";
		case Method.BANK_SLIP:
			return "Boleto Bancário";
		case Method.CREDIT:
			return "Crédito";
		case Method.DEBIT:
			return "Débito";
		case Method.CASH:
			return "Dinheiro";
		case Method.CASHBACK:
			return "Cashback";
		default:
			return "Desconhecido";
	}
}