/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = ({ signed, redirectPath = "/login" }) => {
	if (!signed) return <Navigate to={redirectPath} />;
	return <Outlet />;
};

export default PrivateRoute;
