/* eslint-disable react/prop-types */

import { Spinner } from "react-bootstrap";

const LoadingRow = ({ colSpan }) => (
	<tr className="text-center">
		<td colSpan={colSpan}>
			<Spinner animation="border" role="status" style={{ color: "#e21251" }}>
				<span className="visually-hidden">Loading...</span>
			</Spinner>
		</td>
	</tr>
);

export default LoadingRow;
