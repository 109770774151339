/* eslint-disable global-require */
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import Dinero from "dinero.js";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import { useAuth } from "../../../contexts/AuthContext";
import { TransactionService } from "../../../modules/finance/transaction/TransactionService";
import CancelOrderPopup from "./components/CancelOrderPopup";
import CloseOrderPopup from "./components/CloseOrderPopup";
import DetailsOrderPopup from "./components/DetailsOrderPopup";
import ScheduleOrderPopup from "./components/ScheduleOrderPopup";
import ValidateOrderPopup from "./components/ValidateOrderPopup";
import {
	getStatusName,
	Status,
} from "../../../modules/finance/transaction/enum/Status";
import { ParameterService } from "../../../modules/system/parameter/ParameterService";
import {
	getPaymentName,
	Method,
} from "../../../modules/finance/transaction-payment/enum/Method";
import {
	formatCPF,
	formatPhone,
	maskDataPicker,
	parseDineroToUnitString,
} from "../../../utils/string";
import LoadingRow from "../../../components/LoadingRow";
import OrderItem from "./components/OrderItem";
import LoadingSpinner from "../../../components/LoadingSpinner";
import EmptyRow from "../../../components/EmptyRow";
import DashTitle from "../components/DashTitle";

const listIncrement = 9;

const transactionProvider = new TransactionService();
const parameterService = new ParameterService();

const TransactionPanel = () => {
	const { auth } = useAuth();

	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(listIncrement);
	const [search, setSearch] = useState("");

	const [selectedItem, setSelectedItem] = useState();

	const [loading, setLoading] = useState(true);
	const [pagination, setPagination] = useState();

	const [formRangeDate, setFormRangeDate] = useState([null, null]);

	const [csvExport, setCsvExport] = useState();
	const [transactions, setTransactions] = useState([]);
	const [statusFilter, setStatusFilter] = useState("");
	const [searchFilter, setSearchFilter] = useState("");

	const [receivePix, setReceivePix] = useState(0);
	const [receiveCreditCard, setReceiveCreditCard] = useState(0);

	const getExpectedPaymentDate = useCallback(
		(item) => {
			if (
				!item ||
				(item.payments || []).some((p) => [Method.CASH].includes(p.method))
			)
				return "";
			const purchaseDate = moment(item.purchaseDate);
			const isCreditCard = item.payments.some((p) =>
				["credit", "debit"].includes(p.method)
			);
			if (isCreditCard) {
				return purchaseDate
					.add(receiveCreditCard, "day")
					.format("DD/MM/YYYY HH:mm");
			}
			return purchaseDate.add(receivePix, "day").format("DD/MM/YYYY HH:mm");
		},
		[receiveCreditCard, receivePix]
	);

	const filter = useCallback(
		(order) => {
			if (formRangeDate[0] && formRangeDate[1]) {
				if (
					!moment(order.purchaseDate).isBetween(formRangeDate[0], formRangeDate[1])
				)
					return false;
			}

			const orderStatus =
				order?.status === Status.verified &&
				(order?.payments || []).some((p) => p.method === Method.CREDIT)
					? Status.waiting
					: order?.status;

			if (
				statusFilter &&
				statusFilter.length !== 0 &&
				orderStatus !== statusFilter
			) {
				return false;
			}
			if (!searchFilter || searchFilter.trim().length === 0) return true;

			const searchList = searchFilter.trim().toLowerCase().split("&&");
			if (searchList.some((search) => String(order.orderNumber).includes(search)))
				return true;
			if (
				searchList.some((search) =>
					order.user?.name?.toLowerCase().includes(search)
				)
			)
				return true;
			if (searchList.some((search) => formatCPF(order.user?.cpf).includes(search)))
				return true;
			if (searchList.some((search) => order.user?.cellphone?.includes(search)))
				return true;
			if (
				searchList.some((search) =>
					(order.payments || [])
						.map((p) => getPaymentName(p.method || ""))
						.join("; ")
						.trim()
						.toLowerCase()
						.includes(search)
				)
			)
				return true;
			if (
				searchList.some((search) =>
					getStatusName(order?.status).toLowerCase().includes(search)
				)
			)
				return true;

			return false;
		},
		[searchFilter, statusFilter, formRangeDate]
	);

	const composeCsv = useCallback(() => {
		setCsvExport([
			[
				"pedido",
				"data_pedido",
				"cliente_nome",
				"cliente_cpf",
				"cliente_numero",
				"produto",
				"metodo_pagamento",
				"data_agendamento",
				"status",
				"valor",
				"taxas_cartao",
				"taxas_comercializacao",
				"valor_receber",
				"data_prevista",
				"valor_cashback",
			],
			...transactions.map((item) => {
				const showExpectedPayment = !(item?.payments || []).some((p) =>
					[Method.CASH].includes(p.method)
				);
				return [
					item.orderNumber,
					moment(item?.purchaseDate).format("DD/MM/YYYY HH:mm"),
					item.user?.name,
					formatCPF(item.user?.cpf),
					formatPhone(item.user.phoneNumber || ""),
					item?.items[0]?.product.title || "",
					!item?.payments
						? ""
						: item.payments
								.map((p) => getPaymentName(p.method || ""))
								.join("; ")
								.trim(),
					item?.items[0]?.scheduledDate
						? moment(item?.items[0]?.scheduledDate).format("DD/MM/YYYY HH:mm")
						: "",
					getStatusName(item?.status),
					item !== undefined && item.items !== undefined
						? parseDineroToUnitString(Number(item?.total || 0))
						: "",
					String(item.feePaymentMethod || 0).replace(".", ","),
					String(item.feeTrading || 0).replace(".", ","),
					parseDineroToUnitString(
						showExpectedPayment
							? Dinero({ amount: item.total })
									.subtract(Dinero({ amount: item.totalCashback }))
									.subtract(
										Dinero({ amount: Math.round(item.total * item.feePaymentMethod) })
									)
									.subtract(Dinero({ amount: Math.round(item.total * item.feeTrading) }))
									.subtract(Dinero({ amount: item.total * 0 }))
									.getAmount()
							: Dinero({ amount: 0 }).getAmount()
					),
					getExpectedPaymentDate(item),
					item !== undefined && item.items !== undefined
						? parseDineroToUnitString(Number(item?.totalCashback || 0))
						: "",
				];
			}),
		]);
	}, [getExpectedPaymentDate, transactions]);

	const onCancel = (order) => {
		setSelectedItem(order);
	};
	const onSchedule = (order) => {
		setSelectedItem(order);
	};
	const onValidate = (order) => {
		setSelectedItem(order);
	};
	const onCloseOrder = (order) => {
		setSelectedItem(order);
	};
	const onDetailsOrder = (order) => {
		setSelectedItem(order);
	};

	const onUpdate = () => fetchData();

	const fetchData = useCallback(() => {
		if (!auth) return;
		transactionProvider
			.getTransactionByProviderId(auth.user.provider_id)
			.then((p) => {
				setLoading(false);
				p.data = p.data.sort((a, b) => b.orderNumber - a.orderNumber);
				setPagination(p);
			})
			.catch(setLoading(false));
	}, [auth]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		if (!auth) return;
		parameterService
			.getDeadlineCreditCard()
			.then((deadline) => setReceiveCreditCard(Number(deadline?.value || 0)))
			.catch(() => setReceiveCreditCard(0));
		parameterService
			.getDeadlinePix()
			.then((deadline) => setReceivePix(Number(deadline?.value || 0)))
			.catch(() => setReceivePix(0));
	}, [auth]);

	useEffect(() => {
		composeCsv();
	}, [composeCsv]);

	useEffect(() => {
		if (!pagination) return;
		setTransactions(pagination.data.filter((value) => filter(value)));
	}, [pagination, filter]);

	return (
		<div className="container-fluid">
			<DashTitle title="Pedidos" />
			{loading && <LoadingSpinner />}
			{pagination && (
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<form>
									{/* Filtro por status */}
									<div className="row mb-2">
										<div className="d-flex align-items-center">
											<label htmlFor="status-select" className="col-2">
												Status do Pedido:
											</label>
											<div className="col-3">
												<select
													className="form-select"
													id="status-select"
													onChange={(e) => setStatusFilter(e.target.value)}
												>
													<option className="option" value="">
														Todos
													</option>
													{Object.keys(Status)
														.map((key) =>
															getStatusName(key) === "Desconhecido" ? undefined : key
														)
														.filter((key) => typeof key !== "undefined")
														.map((key) => (
															<option key={key} value={key}>
																{getStatusName(key)}
															</option>
														))}
												</select>
											</div>
											<div className="col-1" style={{ marginLeft: "5px" }}>
												<CSVLink
													data={csvExport || [[]]}
													type="button"
													data-bs-container="#tooltip-pdf"
													data-bs-toggle="tooltip"
													data-bs-placement="bottom"
													title="Exportar para CSV"
													style={{ textDecoration: "none" }}
													onClick={() => true}
													filename="export.csv"
													className="btn btn-info mdi mdi-file-table"
												/>
											</div>
										</div>
									</div>
									{/* Filtro por Período */}
									<div className="row mb-2">
										<div className="d-flex align-items-center">
											<label className="col-2">Período:</label>
											<div className="col-3">
												<DatePicker
													className="form-control"
													selectsRange
													id="dateSchedule"
													locale="pt-BR"
													onChange={(date) => setFormRangeDate(date)}
													onChangeRaw={(e) => maskDataPicker(e.target.value)}
													startDate={formRangeDate[0]}
													endDate={formRangeDate[1]}
													dateFormat="dd/MM/yyyy"
													isClearable
													clearButtonClassName="clear-form-button"
													clearButtonTitle="Limpar"
													disabled={loading}
												/>
											</div>
										</div>
									</div>
									{/* Filtro por Palavra-Chave */}
									<div className="row mb-2">
										<div className="d-flex align-items-center">
											<label className="col-2">Buscar:</label>
											<div className="col-3">
												<input
													type="search"
													className="form-control"
													placeholder="Buscar..."
													value={searchFilter}
													onChange={(e) => setSearchFilter(e.target.value)}
												/>
											</div>
											<div className="col-1" style={{ marginLeft: "5px" }}>
												<button
													type="button"
													data-bs-container="#tooltip-pdf"
													data-bs-toggle="tooltip"
													data-bs-placement="bottom"
													title="Atualizar"
													style={{ textDecoration: "none" }}
													onClick={(e) => {
														e.preventDefault();
														setLoading(true);
														fetchData();
													}}
													className="btn btn-primary"
												>
													<i className="mdi mdi-refresh" />
												</button>
											</div>
										</div>
									</div>
								</form>
								<div className="table-responsive">
									<table
										className="table table-centered w-100 dt-responsive nowrap"
										id="products-datatable"
									>
										<thead className="table-light">
											<tr>
												<th scope="col">Pedido</th>
												<th scope="col">Data Pedido</th>
												<th scope="col">Cliente</th>
												<th scope="col">Serviço</th>
												<th scope="col">Método Pag.</th>
												<th scope="col">Data Agendamento</th>
												<th scope="col">Status</th>
												<th scope="col">Valor</th>
												<th scope="col">Taxas Cartão</th>
												<th scope="col">Taxas Comercialização</th>
												<th scope="col">Valor a Receber</th>
												<th scope="col">Data Prevista do Recebimento</th>
												<th scope="col">Valor do Cashback</th>
												<th
													scope="col"
													className="width-130"
													style={{ textAlign: "center" }}
												>
													Ação
												</th>
											</tr>
										</thead>
										<tbody>
											{loading && <LoadingRow colSpan="10" />}
											{!loading &&
												transactions.length !== 0 &&
												transactions.map((value) => (
													<OrderItem
														key={`order${value.id}`}
														item={value}
														receivePix={receivePix}
														receiveCreditCard={receiveCreditCard}
														callbacks={{
															onCancel,
															onSchedule,
															onValidate,
															onCloseOrder,
															onDetailsOrder,
														}}
													/>
												))}
											{!loading && transactions.length === 0 && <EmptyRow colSpan="10" />}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			<DetailsOrderPopup item={selectedItem} setLoading={setLoading} />
			<ValidateOrderPopup
				item={selectedItem}
				setLoading={setLoading}
				onUpdate={onUpdate}
			/>
			<ScheduleOrderPopup
				item={selectedItem}
				setLoading={setLoading}
				onUpdate={onUpdate}
			/>
			<CancelOrderPopup
				item={selectedItem}
				setLoading={setLoading}
				onUpdate={onUpdate}
			/>
			<CloseOrderPopup
				item={selectedItem}
				setLoading={setLoading}
				onUpdate={onUpdate}
			/>
		</div>
	);
};

export default TransactionPanel;
