/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import { useCallback } from "react";
import moment from "moment";
import Moment from "react-moment";
import Dinero from "dinero.js";
import {
	Status,
	getStatusName,
	getStatusBadge,
} from "../../../../modules/finance/transaction/enum/Status";
import {
	formatCPF,
	formatPhone,
	formatToLocaleCurrency,
} from "../../../../utils/string";
import {
	getPaymentName,
	Method,
} from "../../../../modules/finance/transaction-payment/enum/Method";

const OrderItem = ({ item, receivePix, receiveCreditCard, callbacks }) => {
	const { onCancel, onSchedule, onValidate, onCloseOrder, onDetailsOrder } =
		callbacks;

	const getExpectedPaymentDate = useCallback(() => {
		if (
			!item ||
			(item.payments || []).some((p) => [Method.CASH].includes(p.method))
		)
			return null;
		const purchaseDate = moment(item.purchaseDate);
		const isCreditCard = (item.payments || []).some((p) =>
			[Method.CREDIT, Method.DEBIT].includes(p.method)
		);
		if (isCreditCard) {
			return purchaseDate.add(receiveCreditCard, "day").toDate();
		}
		return purchaseDate.add(receivePix, "day").toDate();
	}, [item, receiveCreditCard, receivePix]);

	const formatName = (name) => {
		if (!name) return "";
		const [firstName, lastName] = name.split(" ");
		return `${firstName} ${lastName || ""}`.trim();
	};

	const status =
		item?.status === Status.verified &&
		(item?.payments || []).some((p) => p.method === Method.CREDIT)
			? Status.waiting
			: item?.status;

	const showExpectedPayment = !(item?.payments || []).some((p) =>
		[Method.CASH].includes(p.method)
	);

	return (
		<tr>
			<th scope="row">
				<span className="text-body fw-bold">#{Number(item.orderNumber)}</span>{" "}
			</th>
			<td>
				<Moment format="L">{moment(item?.purchaseDate).toDate()}</Moment>
				<small className="text-muted">
					:<Moment format="LT">{moment(item?.purchaseDate).toDate()}</Moment>
				</small>
			</td>
			<td id={`tooltip-client-${item.id}`}>
				<span className={item?.user.cancellationDate ? "text-danger" : ""}>
					{formatName(item.user.name)}{" "}
					<i
						data-bs-container={`#tooltip-client-${item.id}`}
						data-bs-toggle="tooltip"
						data-bs-placement="top"
						title={item.user.name || ""}
						className="uil uil-info-circle"
					/>
				</span>
				<br />
				<span className="small">{formatCPF(item.user.cpf || "")}</span>
				<br />
				<span className="small">{formatPhone(item.user.phoneNumber || "")}</span>
			</td>
			<td>{item?.items[0]?.product.title}</td>
			<td>
				{!item?.payments
					? ""
					: item.payments
							.map((p) => getPaymentName(p.method || ""))
							.join("; ")
							.trim()}
			</td>
			<td>
				{!item?.items[0]?.scheduledDate ? (
					""
				) : (
					<>
						<Moment format="L">
							{moment(item.items[0]?.scheduledDate).toDate()}
						</Moment>
						<small className="text-muted">
							:
							<Moment format="LT">
								{moment(item.items[0]?.scheduledDate).toDate()}
							</Moment>
						</small>
					</>
				)}
			</td>
			<td>
				<h6>
					<span className={`badge rounded-pill ${getStatusBadge(status)}`}>
						{getStatusName(status)}
					</span>
				</h6>
			</td>
			<td>
				{item !== undefined && item.items !== undefined
					? formatToLocaleCurrency(Number(item?.total || 0))
					: ""}
			</td>
			<td>{((item?.feePaymentMethod || 0) * 100).toFixed(2)}%</td>
			<td>{((item?.feeTrading || 0) * 100).toFixed(2)}%</td>
			<td>
				{formatToLocaleCurrency(
					showExpectedPayment
						? Dinero({ amount: item.total })
								.subtract(Dinero({ amount: item.totalCashback }))
								.subtract(
									Dinero({ amount: Math.round(item.total * item.feePaymentMethod) })
								)
								.subtract(Dinero({ amount: Math.round(item.total * item.feeTrading) }))
								.subtract(Dinero({ amount: item.total * 0 }))
								.getAmount()
						: Dinero({ amount: 0 }).getAmount()
				)}
			</td>
			<td>
				{showExpectedPayment && (
					<Moment format="L">{getExpectedPaymentDate()}</Moment>
				)}
			</td>
			<td>
				{item !== undefined && item.items !== undefined
					? formatToLocaleCurrency(Number(item?.totalCashback || 0))
					: ""}
			</td>
			<td id={`tooltip-action-${item.id}`} style={{ textAlign: "right" }}>
				{[Status.paid].includes(item.status) && (
					<span
						className="action-icon"
						id={`openModalClose${item.id}`}
						data-bs-toggle="modal"
						data-bs-target="#closeOrderModal"
						onClick={() => onCloseOrder(item)}
						style={{ cursor: "pointer" }}
						role="button"
						tabIndex={0}
					>
						<i
							data-bs-container={`#tooltip-action-${item.id}`}
							data-bs-toggle="tooltip"
							data-bs-placement="bottom"
							title="Fechar pedido"
							className="mdi mdi-check"
						/>
					</span>
				)}
				{[
					Status.new,
					Status.inProcess,
					Status.preAuthorized,
					Status.paid,
					Status.verified,
					Status.waiting,
				].includes(item.status) && (
					<span
						className="action-icon"
						id={`openModalSchedule${item.id}`}
						data-bs-toggle="modal"
						data-bs-target="#scheduleOrderModal"
						onClick={() => onSchedule(item)}
						style={{ cursor: "pointer" }}
						role="button"
						tabIndex={0}
					>
						<i
							data-bs-container={`#tooltip-action-${item.id}`}
							data-bs-toggle="tooltip"
							data-bs-placement="bottom"
							title="Agendar"
							className="mdi mdi-calendar-outline"
						/>
					</span>
				)}
				{[
					Status.new,
					Status.inProcess,
					Status.preAuthorized,
					Status.paid,
					Status.verified,
					Status.waiting,
				].includes(item.status) && (
					<span
						data-bs-toggle="modal"
						data-bs-target="#cancelOrderModal"
						className="action-icon"
						onClick={() => onCancel(item)}
						role="button"
						tabIndex={0}
					>
						<i
							data-bs-container={`#tooltip-action-${item.id}`}
							data-bs-toggle="tooltip"
							data-bs-placement="bottom"
							title="Cancelar"
							className="mdi mdi-cancel"
						/>
					</span>
				)}
				{[Status.new, Status.verified].includes(item.status) && (
					<span
						className="action-icon"
						data-bs-toggle="modal"
						data-bs-target="#validateOrderModal"
						onClick={() => onValidate(item)}
						style={{ cursor: "pointer" }}
						role="button"
						tabIndex={0}
					>
						<i
							data-bs-container={`#tooltip-action-${item.id}`}
							data-bs-toggle="tooltip"
							data-bs-placement="bottom"
							title="Alterar pedido"
							className="mdi mdi-square-edit-outline"
						/>
					</span>
				)}
				<span
					className="action-icon"
					data-bs-toggle="modal"
					data-bs-target="#detailsOrderModal"
					onClick={() => onDetailsOrder(item)}
					style={{ cursor: "pointer" }}
					role="button"
					tabIndex={0}
				>
					<i
						data-bs-container={`#tooltip-action-${item.id}`}
						data-bs-toggle="tooltip"
						data-bs-placement="bottom"
						title="Visualizar pedido"
						className="mdi mdi-eye-outline"
					/>
				</span>
			</td>
		</tr>
	);
};

export default OrderItem;
