import { VividusAPI } from "../../../apis/VividusAPI";

export class AuthService {
	constructor() {
		this.api = new VividusAPI();
	}

	loginToken(auth_id) {
		return this.api.postLoginToken(auth_id);
	}

	getVerifyByEmail(email) {
		return this.api.getVerifyMember({ email });
	}
}
