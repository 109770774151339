/* eslint-disable react/prop-types */
import Moment from "react-moment";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
	formatCPF,
	formatPhone,
	formatToLocaleCurrency,
} from "../../../../utils/string";
import { getPaymentName } from "../../../../modules/finance/transaction-payment/enum/Method";
import { getStatusName } from "../../../../modules/finance/transaction/enum/Status";

const DetailsOrderPopup = ({ item }) => (
	<div
		id="detailsOrderModal"
		className="modal fade"
		tabIndex="-1"
		role="dialog"
		aria-labelledby="success-header-modalLabel"
		aria-hidden="true"
	>
		<div className="modal-dialog modal-dialog-centered">
			<div className="modal-content">
				<div className="modal-header modal-colored-header bg-secondary">
					<h4 className="modal-title" id="success-header-modalLabel">
						Detalhes do pedido
					</h4>
					<button
						type="button"
						className="btn-close"
						data-bs-dismiss="modal"
						aria-hidden="true"
					/>
				</div>
				<div className="modal-body">
					<h4 className="header-title mb-2">{item?.items[0]?.title}</h4>
					<p className="mb-1">
						<span className="fw-bold me-2">Status:</span>{" "}
						{getStatusName(item?.status)}
					</p>
					<p className="mb-1">
						<span className="fw-bold me-2">Presencial:</span>{" "}
						{item?.presential ? "Sim" : "Não"}
					</p>
					{item?.cancellationDate != null ? (
						<p className="mb-1">
							<span className="fw-bold me-2">Cancelado:</span>{" "}
							{item?.cancellationUser && (
								<span className="ml-2">
									{item?.cancellationUser.name}
									{"; "}
								</span>
							)}
							<Moment format="L LT">{moment(item.cancellationDate).toDate()}</Moment>
						</p>
					) : (
						""
					)}
					<p className="mb-3">
						<span className="fw-bold me-2">Agendado:</span>{" "}
						{!item?.items[0]?.scheduledDate ? (
							"Não agendado"
						) : (
							<Moment format="L LT">
								{moment(item.items[0].scheduledDate).toDate()}
							</Moment>
						)}
					</p>
					{item?.payments !== undefined ? (
						<>
							<h4 className="header-title mb-2">Pagamento</h4>
							<p className="mb-1">
								<span className="fw-bold me-2">Formas de Pagamento:</span>{" "}
								{item?.payments !== undefined
									? item.payments
											.map((payment) => getPaymentName(payment.method))
											.join(", ")
									: ""}
							</p>
						</>
					) : (
						""
					)}
					{item?.items[0]?.totalDiscount !== undefined &&
						item?.items[0]?.totalDiscount !== 0 && (
							<p className="mb-1">
								<span className="fw-bold me-2">Cashback %:</span>{" "}
								{Math.round(item.items[0].totalDiscount)}
							</p>
						)}
					<p className="mb-3">
						<span className="fw-bold me-2">Valor:</span>{" "}
						{formatToLocaleCurrency(Number(item?.total || 0))}
					</p>
					<h4 className="header-title mb-2">Cliente</h4>
					<p className="mb-1">
						<span className="fw-bold me-2">Nome:</span> {item?.user.name}{" "}
						{item?.user.cancellationDate && <span>(Inativo)</span>}
					</p>
					<p className="mb-1">
						<span className="fw-bold me-2">CPF:</span> {formatCPF(item?.user.cpf)}
					</p>
					{item?.user?.email && (
						<p className="mb-1">
							<span className="fw-bold me-2">E-mail:</span> {item?.user.email}
						</p>
					)}
					<p className="mb-1">
						<span className="fw-bold me-2">Telefone:</span>{" "}
						{formatPhone(item?.user.phoneNumber)}
					</p>
				</div>
			</div>
		</div>
	</div>
);

export default DetailsOrderPopup;
