import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthContext";

const SideBarItem = ({ title, url, icon, permissions }) => {
	const { hasPermission } = useAuth();

	const hasLocalPermission = () => !permissions || hasPermission(permissions);

	return hasLocalPermission() ? (
		<li className="side-nav-item">
			<Link to={url} className="side-nav-link">
				{icon && <i className={icon} />}
				<span> {title} </span>
			</Link>
		</li>
	) : null;
};

export default SideBarItem;
