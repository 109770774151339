/* eslint-disable react/prop-types */

import { Container, Spinner } from "react-bootstrap";

const LoadingSpinner = () => (
	<Container
		className="d-flex align-items-center justify-content-center"
		style={{ minHeight: "100vh" }}
	>
		<Spinner animation="border" role="status" style={{ color: "#e21251" }}>
			<span className="visually-hidden">Loading...</span>
		</Spinner>
	</Container>
);

export default LoadingSpinner;
