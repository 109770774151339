import { VividusAPI } from "../../../apis/VividusAPI";

export class ParameterService {
	constructor() {
		this.api = new VividusAPI();
	}

	getDeadlinePix() {
		return this.api.getParameterValue("deadline-pix");
	}

	getDeadlineCreditCard() {
		return this.api.getParameterValue("deadline-credit-card");
	}
}
