/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import { useCallback, useState } from "react";

import "react-datepicker/dist/react-datepicker.css";
import { formatCPF, maskDataPicker } from "../../../../utils/string";
import { TransactionService } from "../../../../modules/finance/transaction/TransactionService";
import { useToast } from "../../../../hooks/Toast";

const transactionProvider = new TransactionService();

const ScheduleOrderPopup = ({ item, setLoading, onUpdate }) => {
	const { showSuccess, showError } = useToast();

	const [dateSchedule, setDateSchedule] = useState();

	const handleChangeRaw = (e) => {
		e.target.value = maskDataPicker(e.target.value);
	};

	const handleScheduleOrder = useCallback(
		async (e) => {
			if (!item) return;
			if (!dateSchedule) {
				showError("Data de agendamento não informada!");
				setTimeout(() => {
					document.getElementById(`openModalSchedule${item.id}`).click();
				}, 500);
				return;
			}
			const schedule = moment(dateSchedule);
			const now = moment();
			if (schedule.isBefore(now)) {
				showError("A data de agendamento não pode ser anterior a hoje!");
				setTimeout(() => {
					document.getElementById(`openModalSchedule${item.id}`).click();
				}, 500);
				return;
			}
			setLoading(true);
			try {
				await transactionProvider.scheduleTransaction(item.id, schedule.toDate());
				item.items[0].scheduledDate = schedule.toDate();
				showSuccess("Pedido Finalizado com sucesso");
				if (onUpdate) onUpdate(item);
			} catch (error) {
				showError(error);
			}
			setDateSchedule(undefined);
			setLoading(false);
		},
		[dateSchedule, item, setLoading, onUpdate]
	);

	return (
		<div
			className="modal fade"
			id="scheduleOrderModal"
			tabIndex="-1"
			role="dialog"
			aria-hidden="true"
			data-backdrop="static"
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header modal-colored-header bg-primary">
						<h4 className="modal-title" id="myCenterModalLabel">
							Agendar - {item?.items[0].product.title}
						</h4>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-hidden="true"
						/>
					</div>
					<div className="modal-body">
						<h4 className="header-title mb-2">Cliente</h4>
						<p className="mb-1">
							<span className="fw-bold me-2">{item?.user.name}</span>
						</p>
						<p className="mb-3">
							<span className="fw-bold me-2">CPF:</span> {formatCPF(item?.user.cpf)}
						</p>
						<div className="mb-3">
							<span htmlFor="orderValue" className="form-label">
								Data Agendamento:
							</span>
							<DatePicker
								className="form-control"
								id="dateSchedule"
								locale="pt-BR"
								placeholderText="Defina data e hora"
								onChange={(date) => setDateSchedule(date)}
								onChangeRaw={(e) => handleChangeRaw(e)}
								selected={dateSchedule}
								showTimeSelect
								dateFormat="dd/MM/yyyy HH:mm"
								timeFormat="HH:mm"
							/>
						</div>
						<p className="mb-3 font-14">
							É de responsabiliade do Parceiro Vívidus assegurar-se que o serviço
							esteja sendo prestado ao titular da assinatura Vívidus cujo nome e CPF
							são exibidos nesta tela.
						</p>
					</div>
					<div className="modal-footer">
						<button
							id="btnScheduleOrder"
							type="button"
							className="btn btn-primary"
							data-bs-dismiss="modal"
							onClick={(e) => handleScheduleOrder(e)}
						>
							Agendar Pedido
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ScheduleOrderPopup;
