import { VividusError } from "../errors";

export const getErrorDetails = (message) => {
	if (typeof message === "string") {
		return [
			{
				message,
			},
			"",
		];
	}
	if (message instanceof VividusError) {
		return [
			{
				status: message.status,
				message: message.message,
				data: message.data,
			},
			"VividusError",
		];
	}
	if (message instanceof Error) {
		return [
			{
				message: message.message,
			},
			message.name || "Erro Interno",
		];
	}
	return [
		{
			message: JSON.stringify(message),
		},
		"",
	];
};
