/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import Dinero from "dinero.js";
import { Status } from "../../../../modules/finance/transaction/enum/Status";
import { TransactionService } from "../../../../modules/finance/transaction/TransactionService";
import { useToast } from "../../../../hooks/Toast";

/**
 * Convertes a string de porcentagem visual para number que será persistido no banco
 * @returns
 */
const percStringToFloat = (str = "0") =>
	parseFloat(String(parseFloat(str.replace(",", ".")) * 0.01));

const warningBorder = "1px solid #ffbc00";
const invalidBorder = "1px solid #f44336";
const defaultBorder = "1px solid #dee2e6";

const transactionProvider = new TransactionService();

const ValidateOrderPopup = ({ item, setLoading, onUpdate }) => {
	const { showSuccess, showError } = useToast();

	const [selectedPrice, setSelectedPrice] = useState(0);
	const [selectedCashback, setSelectedCashback] = useState(0);

	const [priceBorder, setPriceBorder] = useState(defaultBorder);
	const [cashbackBorder, setCashbackBorder] = useState(defaultBorder);

	const handleAproveOrder = async () => {
		setLoading(true);
		try {
			if (selectedPrice < 0) {
				showError("Valor não pode ser negativo.");
				return;
			}
			if (selectedCashback < 0) {
				showError("Cashback não pode ser negativo.");
				return;
			}
			item = await transactionProvider.patchTransaction(
				item.id,
				selectedPrice,
				percStringToFloat(selectedCashback.toString())
			);
			showSuccess("Alterado com sucesso");
			if (onUpdate) onUpdate(item);
		} catch (error) {
			showError(error.message);
		}
		setLoading(false);
	};

	useEffect(() => {
		if (!item) {
			setSelectedPrice(0);
			setSelectedCashback(0);
			return;
		}
		const { totalRaw, totalCashback = 0 } = item;
		setSelectedPrice(totalRaw);
		let preCashback = (totalRaw ? (totalCashback / totalRaw) * 100 : 0).toFixed(
			0
		);
		preCashback =
			preCashback === 0
				? preCashback
				: Math.round(item.items[0].product.discount * 100);
		setSelectedCashback(preCashback);
	}, [item]);

	return (
		<div
			className="modal fade"
			id="validateOrderModal"
			tabIndex="-1"
			role="dialog"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header modal-colored-header bg-primary">
						<h4 className="modal-title">Alterar - {item?.items[0].product.title}</h4>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-hidden="true"
						/>
					</div>
					<div className="modal-body">
						<h4 className="header-title mb-2">Cliente</h4>
						<p className="mb-3">
							<span className="fw-bold me-2">{item?.user.name}</span>
						</p>
						{!(item?.payments || []).some((p) => p.payment_id) &&
							(item?.payments || []).some((p) => p.presential) &&
							[Status.new, Status.verified].includes(item?.status) && (
								<div className="mb-3">
									<span htmlFor="orderValue" className="form-label">
										Valor R$:
									</span>
									<input
										id="orderValue"
										step="0.01"
										className="form-control"
										disabled={
											item?.payments !== undefined &&
											item?.payments[0] !== undefined &&
											!!item?.payments[0].adiq
										}
										onChange={(e) => {
											const { value } = e.target;
											const number = value.replace(/[^0-9]+/g, "") || 0;
											const price = parseInt(number, 10);
											if (price < 0) {
												setPriceBorder(invalidBorder);
											} else {
												setPriceBorder(defaultBorder);
											}
											setSelectedPrice(price);
										}}
										value={Dinero({ amount: Number(selectedPrice || 0) }).toFormat()}
										placeholder="Valor do pedido"
										style={{
											border: priceBorder,
										}}
									/>
								</div>
							)}
						<div className="mb-3">
							<span htmlFor="orderDiscount" className="form-label">
								Cashback %:
							</span>{" "}
							{cashbackBorder === warningBorder && (
								<span>
									<small className="fw-bold text-warning">
										Cashback {selectedCashback === 0 ? "de 0" : "superior a 50"}%.
									</small>
								</span>
							)}
							<input
								id="orderDiscount"
								step="1"
								className="form-control"
								disabled
								onChange={(e) => {
									const { value } = e.target;
									const number = value.replace(/[^0-9]+/g, "") || 0;
									const cashback = parseInt(number, 10);
									if (cashback <= 0 || cashback > 50) {
										if (cashback > 50 || cashback === 0) {
											setCashbackBorder(warningBorder);
										} else {
											setCashbackBorder(invalidBorder);
										}
									} else {
										setCashbackBorder(defaultBorder);
									}
									setSelectedCashback(cashback);
								}}
								value={selectedCashback}
								placeholder="Cashback do pedido"
								style={{
									border: cashbackBorder,
								}}
							/>
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							data-bs-dismiss="modal"
							onClick={() => handleAproveOrder()}
						>
							Alterar Pedido
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ValidateOrderPopup;
