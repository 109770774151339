import { VividusAPI } from "../../../apis/VividusAPI";

export class TransactionService {
	constructor() {
		this.api = new VividusAPI();
	}

	getTransactionByProviderId(provider_id) {
		return this.api.getTransactionPaginated({
			order: "ASC",
			take: 0,
			loadCancellationUser: true,
			loadUser: true,
			loadItens: true,
			loadProducts: true,
			loadPayments: true,
			transactionTypes: ["P", "S"],
			provider_id,
		});
	}

	patchTransaction(transactionId, newValue, newCashBack) {
		return this.api.patchTransaction(transactionId, newValue, newCashBack);
	}

	cancelTransaction(transactionId) {
		return this.api.deleteCancelTransaction(transactionId);
	}

	closeTransaction(transactionId) {
		return this.api.postCloseTransaction(transactionId);
	}

	scheduleTransaction(transactionId, date) {
		return this.api.postScheduleTransaction(transactionId, date);
	}
}
