import Dinero from "dinero.js";
import moment from "moment-timezone";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";
import "moment/locale/pt-br";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBr from "date-fns/locale/pt-BR";
import { AuthProvider } from "./contexts/AuthContext";
import Routes from "./routes";

import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/icons.css";
import "./assets/css/app-modern.css";

Dinero.defaultCurrency = "BRL";
Dinero.defaultPrecision = 2;
Dinero.defaultAmount = 0;
Dinero.globalLocale = "pt-BR";

moment.locale("pt-br");
moment.tz.setDefault("America/Sao_Paulo");

registerLocale("pt-BR", ptBr);
setDefaultLocale("pt-BR");

const App = () => (
	<AuthProvider>
		<ToastContainer />
		<Router>
			<Routes />
		</Router>
	</AuthProvider>
);

export default App;
