/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from "react";
import { Spinner, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useToast } from "../../hooks/Toast";
import { emailRegex } from "../../utils/string";
import { useAuth } from "../../contexts/AuthContext";

const invalidBorder = "1px solid #f44336";
const validBorder = "1px solid #4caf50";

const ResetPasswordPage = () => {
	const { showSuccess, showError } = useToast();
	const { currentUser, resetPassword } = useAuth();
	const navigate = useNavigate();

	const [emailBorder, setEmailBorder] = useState("1px solid #dee2e6");

	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);

	if (currentUser) {
		navigate("/order", { replace: true });
	}

	const handleChangeEmail = (event) => {
		const { value } = event.target;
		if (!value || !emailRegex.test(value)) {
			setEmailBorder(invalidBorder);
		} else {
			setEmailBorder(validBorder);
		}
		setEmail(value);
	};

	async function handleResetPassword(e) {
		e.preventDefault();
		if (!email) {
			showError("E-mail não informado.");
			setEmailBorder(invalidBorder);
			return;
		}
		if (!emailRegex.test(email)) {
			showError("E-mail inválido.");
			return;
		}
		setLoading(true);
		try {
			await resetPassword(email);
			setLoading(false);
			showSuccess("Email de redefinição de senha enviado.");
			setTimeout(() => {
				navigate("/order", { replace: true });
			}, 5000);
		} catch (e) {
			let errorMessage = "";
			switch (e.code) {
				case "auth/user-not-found":
					errorMessage += "Usuário ou e-mail não encontrado.";
					break;
				case "auth/invalid-email":
					errorMessage += "Email inválido.";
					break;
				case "auth/wrong-password":
					errorMessage += "Senha inválida ou usuário não possui senha.";
					break;
				default:
					errorMessage += `Erro ao enviar email: ${e.message}`;
					break;
			}
			console.error(e);
			showError(errorMessage);
			setLoading(false);
		}
	}

	function handleBackToSignIn() {
		navigate("/order", { replace: true });
	}

	return (
		<div style={{ width: "100%", height: "100%", backgroundColor: "#D3D0DC" }}>
			<ToastContainer />
			{loading ? (
				<Container
					className="d-flex align-items-center justify-content-center"
					style={{ minHeight: "100vh" }}
				>
					<Spinner animation="border" role="status" style={{ color: "#e21251" }}>
						<span className="visually-hidden">Loading...</span>
					</Spinner>
				</Container>
			) : (
				<div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-xxl-4 col-lg-5">
								<div className="card">
									<div className="card-header pt-4 pb-4 text-center bg-primary">
										<a href="/">
											<svg
												height="30"
												viewBox="0 0 945 290"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M125.009 102.01C131.29 102.01 137.329 104.317 143.127 108.932C149.166 113.304 152.185 117.919 152.185 122.776C152.185 131.52 144.938 151.801 130.445 183.618C116.192 215.436 102.906 241.059 90.5865 260.49C78.5083 279.92 69.0873 289.636 62.3235 289.636C58.7 289.636 53.3856 287.207 46.3803 282.349C39.6165 277.492 35.2683 272.634 33.3358 267.776C27.5383 253.689 20.5329 227.701 12.3198 189.812C4.10659 151.679 0 127.513 0 117.312C0 107.111 3.3819 102.01 10.1457 102.01C18.3589 102.01 26.8136 105.289 35.5099 111.847C50.0037 123.019 58.0961 137.956 59.7871 156.658C60.5118 165.402 61.1157 176.21 61.5988 189.083C62.0819 201.713 63.169 212.642 64.8599 221.872C70.6575 210.699 77.7836 194.183 86.2383 172.324C94.6931 150.222 101.94 132.977 107.979 120.59C114.26 108.204 119.936 102.01 125.009 102.01Z"
													fill="white"
												/>
												<path
													d="M185.476 102.01C193.931 102.01 201.419 106.868 207.941 116.583C214.705 126.298 218.087 135.649 218.087 144.636C218.087 149.25 213.86 165.888 205.405 194.548C197.192 222.965 188.978 249.925 180.765 275.427C177.867 284.9 171.707 289.636 162.286 289.636C156.971 289.636 152.502 287.693 148.879 283.807C145.255 279.678 143.444 275.063 143.444 269.962C143.444 264.862 144.168 258.668 145.618 251.382C147.067 243.853 149.241 233.894 152.14 221.508C155.28 209.121 157.454 199.527 158.662 192.726C160.112 185.925 161.682 177.668 163.373 167.952C165.305 158.237 166.875 150.222 168.083 143.907C169.291 137.349 170.74 130.549 172.431 123.505C176.296 109.175 180.645 102.01 185.476 102.01ZM203.231 92.5377C199.366 92.5377 195.984 90.8375 193.085 87.4372C190.428 83.794 189.099 79.665 189.099 75.0502C189.099 70.1926 190.549 65.5779 193.447 61.206C196.346 56.5913 200.694 51.8551 206.492 46.9975C212.531 41.897 217.725 37.4037 222.073 33.5176C226.662 29.3886 230.044 26.474 232.218 24.7739C234.393 22.8308 236.808 20.7663 239.465 18.5804C242.123 16.3945 244.176 14.8157 245.625 13.8442C249.249 11.9012 252.389 10.9296 255.046 10.9296C260.844 10.9296 266.158 14.33 270.989 21.1306C275.821 27.6884 278.236 33.639 278.236 38.9824C278.236 44.3258 277.753 48.2119 276.787 50.6407C276.062 52.8266 274.13 55.0126 270.989 57.1985C268.091 59.3844 265.192 61.206 262.293 62.6633C259.636 64.1206 254.925 66.5494 248.162 69.9497C241.639 73.1072 236.325 75.7789 232.218 77.9648C228.353 80.1507 224.368 82.5796 220.261 85.2513C213.014 90.1089 207.337 92.5377 203.231 92.5377Z"
													fill="white"
												/>
												<path
													d="M361.129 102.01C367.409 102.01 373.448 104.317 379.246 108.932C385.285 113.304 388.305 117.919 388.305 122.776C388.305 131.52 381.058 151.801 366.564 183.618C352.312 215.436 339.026 241.059 326.706 260.49C314.628 279.92 305.207 289.636 298.443 289.636C294.819 289.636 289.505 287.207 282.5 282.349C275.736 277.492 271.388 272.634 269.455 267.776C263.658 253.689 256.652 227.701 248.439 189.812C240.226 151.679 236.119 127.513 236.119 117.312C236.119 107.111 239.501 102.01 246.265 102.01C254.478 102.01 262.933 105.289 271.629 111.847C286.123 123.019 294.215 137.956 295.906 156.658C296.631 165.402 297.235 176.21 297.718 189.083C298.201 201.713 299.288 212.642 300.979 221.872C306.777 210.699 313.903 194.183 322.358 172.324C330.812 150.222 338.059 132.977 344.098 120.59C350.379 108.204 356.056 102.01 361.129 102.01Z"
													fill="white"
												/>
												<path
													d="M414.227 56.4698C414.227 50.1549 417.005 43.7186 422.561 37.1608C428.359 30.3601 433.915 26.9598 439.229 26.9598C444.785 26.9598 451.307 33.5176 458.796 46.6332C466.284 59.7487 470.029 69.3425 470.029 75.4146C470.029 81.2437 468.096 85.737 464.231 88.8945C460.366 92.0519 456.38 93.6307 452.274 93.6307C445.268 93.6307 437.176 89.0159 427.997 79.7864C418.817 70.557 414.227 62.7848 414.227 56.4698ZM419.663 102.01C428.117 102.01 435.606 106.868 442.128 116.583C448.892 126.298 452.274 135.649 452.274 144.636C452.274 149.25 448.046 165.888 439.592 194.548C431.378 222.965 423.165 249.925 414.952 275.427C412.053 284.9 406.135 289.636 397.197 289.636C391.883 289.636 387.172 287.693 383.066 283.807C379.201 279.678 377.268 274.456 377.268 268.141C377.268 261.583 380.288 245.553 386.327 220.05C392.607 194.305 397.318 172.446 400.458 154.472C403.84 136.256 406.86 123.019 409.517 114.761C412.416 106.26 415.798 102.01 419.663 102.01Z"
													fill="white"
												/>
												<path
													d="M607.031 0C612.829 0 620.438 5.34338 629.859 16.0301C639.28 26.474 643.991 36.3107 643.991 45.5402C643.991 50.1549 639.763 69.3425 631.308 103.103C622.854 136.863 617.781 157.265 616.09 164.309C596.765 248.103 582.633 290 573.695 290C568.623 290 562.946 286.964 556.665 280.892C550.385 274.82 547.244 268.869 547.244 263.04C547.244 261.826 547.365 260.854 547.607 260.126C523.45 280.042 506.058 290 495.429 290C485.042 290 474.896 284.414 464.992 273.241C455.329 261.826 450.498 251.503 450.498 242.274C450.498 233.044 451.706 222.843 454.121 211.671C456.779 200.255 460.764 188.233 466.079 175.603C471.393 162.973 477.432 151.193 484.196 140.264C491.201 129.091 499.535 119.983 509.198 112.94C519.102 105.653 529.248 102.01 539.635 102.01C555.095 102.01 567.777 109.904 577.681 125.691C590.726 68.1281 597.006 29.8744 596.523 10.9296C596.523 3.64321 600.026 0 607.031 0ZM500.139 243.731C506.178 243.731 516.324 238.509 530.576 228.065C545.07 217.621 555.82 204.384 562.825 188.354C562.584 186.411 562.463 182.161 562.463 175.603C562.463 169.045 560.289 162.852 555.941 157.023C551.592 151.193 547.607 148.279 543.983 148.279C536.736 148.279 529.731 152.165 522.967 159.937C516.445 167.709 511.251 176.939 507.386 187.626C499.173 210.214 495.066 227.337 495.066 238.995C495.066 240.938 495.308 242.274 495.791 243.003C496.516 243.488 497.965 243.731 500.139 243.731Z"
													fill="white"
												/>
												<path
													d="M754.287 111.118C756.22 105.046 760.085 102.01 765.882 102.01C771.68 102.01 778.323 106.989 785.811 116.947C793.541 126.905 797.406 135.528 797.406 142.814C797.406 145.486 791.85 163.338 780.738 196.369C769.626 229.401 761.051 255.511 755.012 274.698C751.63 284.9 745.953 290 737.982 290C733.15 290 729.044 288.3 725.662 284.9C722.521 281.256 720.951 275.67 720.951 268.141C720.951 260.611 722.401 249.925 725.299 236.08C700.418 272.027 680.61 290 665.875 290C658.145 290 648.844 285.021 637.974 275.063C627.104 264.862 621.669 255.025 621.669 245.553C621.669 222.236 628.191 192.483 641.235 156.294C654.521 120.105 666.962 102.01 678.557 102.01C685.562 102.01 691.722 105.168 697.036 111.482C702.592 117.797 705.37 124.719 705.37 132.249C705.37 135.163 702.351 143.3 696.312 156.658C677.953 196.734 668.773 225.029 668.773 241.545C668.773 243.003 669.377 243.731 670.585 243.731C674.209 243.731 680.973 238.023 690.877 226.608C701.022 214.95 712.255 198.434 724.575 177.06C737.136 155.444 747.04 133.463 754.287 111.118Z"
													fill="white"
												/>
												<path
													d="M904.417 102.374C913.597 102.374 922.655 107.839 931.593 118.769C940.531 129.698 945 139.535 945 148.279C945 154.108 942.464 157.023 937.391 157.023C934.492 157.023 930.265 155.93 924.709 153.744C919.153 151.315 913.476 150.1 907.678 150.1C902.122 150.1 893.789 152.044 882.677 155.93C871.806 159.816 866.371 164.916 866.371 171.231C866.371 173.66 869.632 177.182 876.154 181.796C882.677 186.411 889.803 191.147 897.533 196.005C905.263 200.863 912.389 207.663 918.911 216.407C925.433 224.908 928.694 234.259 928.694 244.46C928.694 254.418 921.327 264.497 906.591 274.698C892.097 284.9 874.946 290 855.138 290C835.572 290 818.783 285.507 804.772 276.52C790.761 267.534 783.756 258.304 783.756 248.832C783.756 239.359 787.621 234.623 795.351 234.623C798.492 234.623 802.236 235.838 806.584 238.266C818.662 245.31 833.639 248.832 851.515 248.832C869.632 248.832 878.691 246.403 878.691 241.545C878.691 236.688 875.188 231.344 868.183 225.515C861.419 219.443 853.93 213.857 845.717 208.756C837.746 203.656 830.257 197.462 823.252 190.176C816.488 182.647 813.106 175.36 813.106 168.317C813.106 155.201 824.943 140.992 848.616 125.691C872.289 110.147 890.89 102.374 904.417 102.374Z"
													fill="white"
												/>
											</svg>
										</a>
									</div>
									<form>
										<div className="card-body p-4">
											<div className="text-center w-75 m-auto">
												<h4 className="text-dark-50 text-center mt-0 fw-bold">
													Redefinir Senha
												</h4>
												<p className="text-muted mb-4">
													Informe seu endereço de email abaixo e será enviado um email com
													instruções de como redefinir sua senha.
												</p>
											</div>

											<div className="mb-3">
												<label htmlFor="emailaddress" className="form-label">
													Email
												</label>
												<input
													className="form-control"
													type="email"
													id="emailaddress"
													required=""
													placeholder="Informe seu email"
													autoComplete="on"
													onChange={handleChangeEmail}
													value={email}
													style={{
														border: emailBorder,
													}}
												/>
											</div>

											<div className="mb-3 mb-0 text-center">
												<button
													type="button"
													className="btn btn-primary"
													onClick={(e) => handleResetPassword(e)}
												>
													{" "}
													Redefinir Senha{" "}
												</button>
											</div>
										</div>
									</form>
								</div>
								<div className="row mt-3">
									<div className="col-12 text-center">
										<p className="text-muted">
											Voltar para{" "}
											<span
												onClick={(e) => handleBackToSignIn(e)}
												role="button"
												tabIndex={0}
												style={{ cursor: "pointer" }}
												className="text-muted ms-1"
											>
												<b>Entrar</b>
											</span>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					)
				</div>
			)}

			<footer className="footer footer-alt">2021 © Vívidus</footer>
		</div>
	);
};

export default ResetPasswordPage;
