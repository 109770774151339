export const Status = {
	new: "new",
	paid: "paid",
	waiting: "waiting",
	inProcess: "in-process",
	preAuthorized: "pre-authorized",
	verified: "verified",
	closed: "closed",
	canceled: "canceled",
	expired: "expired",
	reversed: "reversed",
};

export function getStatusBadge(type) {
	switch (type) {
		case Status.new:
			return "bg-success";
		case Status.verified:
			return "bg-primary";
		case Status.inProcess:
		case Status.preAuthorized:
		case Status.closed:
			return "bg-secondary";
		case Status.canceled:
		case Status.reversed:
			return "bg-danger";
		case Status.waiting:
			return "bg-info";
		default:
			return "bg-warning";
	}
}

export function getStatusName(type) {
	switch (type) {
		case Status.new:
			return "Novo";
		case Status.paid:
			return "Pago";
		case Status.waiting:
			return "Aguardando pagamento";
		case Status.inProcess:
			return "Em processamento";
		case Status.preAuthorized:
			return "Pré-autorizado";
		case Status.verified:
			return "Confirmado";
		case Status.closed:
			return "Fechado";
		case Status.canceled:
			return "Cancelado";
		case Status.expired:
			return "Expirado";
		case Status.reversed:
			return "Estornado";
		default:
			return "Desconhecido";
	}
}
