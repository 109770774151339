import React from "react";
import VividusCardLogo from "../Logo/VividusCardLogo";

const VividusCardPage = ({ children }) => (
	<main className="authentication-bg">
		<div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xxl-4 col-lg-5">
						<div className="card">
							{/* Logo */}
							<VividusCardLogo />
							{/* Body */}
							<div className="card-body p-4">{children}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
);

export default VividusCardPage;
