import { Routes as ReactRoutes, Route, Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import DashboardPage from "../pages/DashboardPage";
import TransactionPanel from "../pages/DashboardPage/TransactionPage";
import PrivateRoute from "./PrivateRoute";
import LoginPage from "../pages/LoginPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";

const Routes = () => {
	const { signed } = useAuth();
	return (
		<ReactRoutes>
			<Route path="*" element={<Navigate to="/order" replace />} />
			<Route path="login" element={<LoginPage />} />
			<Route path="reset-password" element={<ResetPasswordPage />} />
			<Route element={<PrivateRoute signed={signed} />}>
				<Route path="" element={<DashboardPage />}>
					<Route path="order">
						<Route path="" element={<TransactionPanel />} />
					</Route>
				</Route>
			</Route>
		</ReactRoutes>
	);
};

export default Routes;
