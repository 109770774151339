import { VividusAPI } from "../../../apis/VividusAPI";

export class UserService {
	constructor() {
		this.api = new VividusAPI();
	}

	fetchById(id, accesstoken) {
		return this.api.getUser(id, { loadProvider: true }, accesstoken);
	}
}
