import React from "react";
import { CSVLink } from "react-csv";

const ExportCSVButton = ({ data = [[]], handleExport }) => (
	<CSVLink
		data={data}
		type="button"
		data-bs-container="#tooltip-pdf"
		data-bs-toggle="tooltip"
		data-bs-placement="bottom"
		title="Exportar para CSV"
		style={{ textDecoration: "none" }}
		onClick={() => {
			if (handleExport) return handleExport();
			return true;
		}}
		filename="export.csv"
		className="btn btn-info mb-2 me-1 mdi mdi-file-table"
	/>
);

export default ExportCSVButton;
