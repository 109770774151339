import React from "react";
import logo from "../../assets/images/logo.svg";

const VividusCardLogo = () => (
	<div className="card-header pt-4 pb-4 text-center bg-primary">
		<a href="https://www.vividus.com.br/" rel="noreferrer" target="_blank">
			<img src={logo} alt="Vívidus Logo" />
		</a>
	</div>
);

export default VividusCardLogo;
