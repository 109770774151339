import React from "react";

const TopBarDropdown = ({ children }) => (
	<div
		className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown"
		aria-labelledby="topbar-userdrop"
	>
		{children}
	</div>
);

export default TopBarDropdown;
