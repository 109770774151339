/* eslint-disable react/prop-types */

const EmptyRow = ({ colSpan }) => (
	<tr className="text-center">
		<td colSpan={colSpan}>
			<span className="text-body fw-bold">Não existe ordens para exibir 🙁</span>
		</td>
	</tr>
);

export default EmptyRow;
