import { useCallback } from "react";
import { toast } from "react-toastify";
import { getErrorDetails } from "../utils/error";

export const useToast = () => {
	const log = useCallback((message, type) => {
		const [details] = getErrorDetails(message);
		switch (type) {
			case "danger": {
				console.error(message);
				break;
			}
			case "success":
			case "dark":
			default:
				console.log(message);
				break;
		}
		return details.message;
	}, []);

	const show = useCallback(
		(message, type, options) => {
			const displayMessage = log(message, type);
			switch (type) {
				case "danger": {
					toast.error(displayMessage, { onClose: options?.onDidDismiss });
					break;
				}
				case "success": {
					toast.success(displayMessage, { onClose: options?.onDidDismiss });
					break;
				}
				default:
					toast.info(displayMessage, { onClose: options?.onDidDismiss });
					break;
			}
		},
		[log]
	);

	const showSuccess = useCallback(
		(message, options) => show(message, "success", options),
		[show]
	);

	const showDefault = useCallback(
		(message, options) => show(message, "dark", options),
		[show]
	);

	const showError = useCallback(
		(message, options) => show(message, "danger", options),
		[show]
	);

	return { showError, showSuccess, showDefault };
};
